//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

let timeOutSearch = null;
export default {
  name: 'CustomAutocompleteServices',

  data: function () {
    return {
      search: "",

      isOpen: false,
      isLoadSearch: false
    }
  },

  props: {
    value: {}
  },

  computed: {
    options: function () {
      return this.$store?.state?.["languages-list"]?.services || []
    },

    serviceIcon: function () {
      if (this.isOpen) {
        return null
      }
      return String(this.value?.icon)
    },
    serviceName: function () {
      if (this.isOpen) {
        return this.$t('languagesList.Выберите_услугу')
      }
      return this.value?.name || this.$t('languagesList.Выберите_услугу')
    },

    searchCountItems: function () {
      const count = this.$numberFormat(this.$store.state["languages-list"]?.servicesTotalCount || 0);
      const word = String(this.$t('searchPage.placeholder') || '').replace('...', '');
      return `${ word } (${ count })`
    },

    isLoading: function () {
      return this.$store.state?.["languages-list"]?.servicesLoading || false
    },
    isShowSkeleton: function () {
      const currentPage = this.$store.state?.["languages-list"]?.servicesPage;
      const totalPage = this.$store.state?.["languages-list"]?.servicesTotalPage;
      return Boolean(currentPage < totalPage);
    },
    currentPage: function () {
      return this.$store.state?.["languages-list"]?.servicesPage || 1
    }
  },

  watch: {
    search: function () {
      clearTimeout(timeOutSearch);
      this.$store.commit("languages-list/setServicesLoading", Boolean(String(this.search || "").length >= 1));
      timeOutSearch = setTimeout(async () => {
        await this.$store.dispatch("languages-list/getServicesSearch", {
          search: this.search
        });
      }, 1000);
    },
    isOpen: async function () {
      await new Promise(r => setTimeout(r, 100));
      if (!!this.isOpen) {
        this.$refs.refScrollList.addEventListener("scroll", this.eventScrollList);
        this.eventInputRender();
      }
    }
  },

  methods: {
    changeOpen: function (value) {
      this.isOpen = value;

      if (!!value && this.currentPage <= 1) {
        (async () => {
          await this.getItems();
        })();
      }
    },
    changeItem: function (option) {
      this.$emit("change", option);
      this.$store.commit("languages-list/setServicesSelected", option);
      this.changeOpen(false);
      this.$store.dispatch("languages-list/getServicesSearch", {
        search: ""
      });
    },

    getItems: async function () {
      await this.$store.dispatch("languages-list/getServicesMore")
    },

    eventScrollList: async function (event) {
      const currentScrollTop = event.target.scrollTop || 0;
      const startOffsetScrollTop = (event.target.scrollHeight || 0) - (event.target.offsetHeight || 0) - 140;
      if (currentScrollTop >= startOffsetScrollTop && !this.isLoading && this.isShowSkeleton) {
        await this.$store.dispatch("languages-list/getServicesMore");
      }
    },

    eventInputRender: function () {
      if (!this.$refs.refInputSearch) {
        return
      }
      this.$refs.refInputSearch.focus();
    }
  }
}
