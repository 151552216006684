export const state = () => ({
  "ab-test__hide-ruble-currency": ""
});
export const mutations = {
  setCookiesAbTest: function (state, cookiesName) {
    const cookie = this.$cookies.get(cookiesName);
    if (Boolean(String(cookie) && cookie != undefined && cookie != null)) {
      return
    }

    const isUserTest = Boolean(Math.random() > 0.5);
    this.$cookies.set(cookiesName, String(isUserTest), {
      path: "/",
      maxAge: 60 * 60 * 24 * 30 * 12,
    });

    console.log(`AB TEST: Name: ${cookiesName}; Value: ${String(isUserTest)}`);

    state[cookiesName] = String(isUserTest);
    if (this.$yandexMetrika?.userParams) {
      this.$yandexMetrika.userParams({
        [cookiesName]: String(isUserTest)
      });
    }
  }
}
