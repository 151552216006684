export default {
  header: {
    profile: "Profile",
    loginButton: "Log In",
    registrationBtn: "Sign Up",
    feedback: "Feedback",
    language: "Language",
    darkTheme: "Dark theme",
    languageToggle: {
      en: "English",
      ru: "Russian",
      cn: "Chinese",
      tr: "Turkish",
      es: "Spanish",
      hi: "Hindi"
    }
  },
  profileNavMenu: {
    goToProfile: "Profile",
    activeNumber: "Received numbers",
    queryStatistics: "Query statistics",
    balanceHistory: "Balance history",
    tariffs: "Tariffs",
    settings: "Settings",
    balance: "Balance",
    topupBalance: "Top Up balance",
    topupAlert: "",
    logout: "Log Out",
    referralProgram: "Referral program",
    saleAgreement: "Sale of licenses",
  },
  profile: {
    activeNumbers: {
      title: "Received numbers",
      noActiveNumbers: 'No active numbers',
      serverError: 'Server Error',
      status: "Status",
      waitingForSms: "Waiting For Sms",
      time: "Time",
      banned: "In a block?",
      cancel: "Cancel",
      message: "The code accepted and paid. Enter code please",
      messageMultipleSms: 'In the remaining time, you can get new codes from the service for free',
      blockModalMessage1: 'This number was blocked on the service side?<br /> Let us know about it and we will remove the number from the search results',
      blockModalMessage2: 'The funds will be instantly returned to the balance.<br /> Try to buy a new number in the same or another country',
      blockBtn: 'Yes, the number is blocked',
      moreSms: "More Sms",
      availableIn: "Available in: ",
      waitingSms: "Waitng new SMS"
    },
    queryStatistics: {
      title: "Query Statistics",
      noQuery: "No Query",
      buyFor: "Buy for"
    },
    balanceHistory: {
      title: " Balance History",
      outgoing: "Outgoing",
      incoming: "Incoming",
      showMore: "Show More",
      noHistory: " No history",
      numberOrder: "Payment by invoice №",
    },
    tariffs: {
      title: 'Tariffs',
    },
    settings: {
      title: "Settings",
      sound: "Play sound when receiving code",
      infoAboutServices: "Show info about active purchases",
      twoFactorEnabled: "Two-factor authentication",
      infoAboutBalance: "Lower balance threshold for email notification",
      apiKey: "API Key",
      message: "Generate an API key for  interacting the site using the API protocol",
      buttonUpdate: "Update API Key",
      updateError: "Update Error",
      settingsChanged: "Settings changed",
      errorChangingSettings: "Error changing settings",
      phoneNumber: "Your phone number",
      playceholderPhone: "Enter your number",
      refParams: {
        refLink: "Referral program report:",
        refOptions: "Referral Program options:",
      },
    },
    myPersonalInformation: {
      title: "Your personal data",
      yourEmail: "Your e-mail",
      yourName: "Your name",
    },
    referralProgram: {
      title: "Referral program",
      referralLinkTitle: "Referral link",
      buttonCopy: "Copy",
      buttonDownload: "Download",
      topUpAmount: "Spent by your referrals",
      youHaveAlreadyReceived: "Referral payments received",
      awaiting: "Remaining referral balance",
      derived: 'Of them - derived',
      detailing: "Referral system report",
      formTitle: "Submit an application",
      formButton: "Withdrawal Request",
      participatePromotion: "Participate in the promotion",
      earnedYourReferrals: "Earned by your referrals",
      instructions: "Send this registration link to a friend or use it for traffic arbitration. Upon request, withdraw referral fees from the balance — 5% of the default referral turnover — in a way that is convenient for you. The accrued funds can be spent on the purchase of numbers (transfer of amounts from 99 ₽ is carried out upon request to the support service) or withdraw to a card or an electronic wallet. Withdrawal to a crypto wallet — from 100 USDT, to a card — from 5,000 ₽."
    }
  },
  profilePay: {
    sectionBalanceReplenishment: {
      title: "Balance replenishment",
      cardButtonSelect: "Select",
      paymentTitle: "Payment",
      paymentMessage: "Always make a payment through the site interface by clicking the pay button, only then you will transfer money to the correct details",
      paymentMessageAlipay: "The service only supports users in Chinese Mainland Verified. You can try a method for all AliPay users below.",
      paymentPlaceholder: "Enter amount *",
      paymentButton: "Pay",
      paymentErrorAmount: "The top-up amount must be greater than 0",
      payComment: "Commission for replenishment",
      plasticCard: "Bank cards: only Russia  🇷🇺",
      minAmount: "Minimum payment amount {minPrice} ₽",
      minAmountMessage: "Minimum payment amount {minPrice} ₽",
      payMessageForPayeer: "The selected replenishment method, grizzlysms.com services are provided by THREETWOTWO LTD. To continue, you must agree to the documents described below.",
      payMessageForPaypalych: "Bank cards: only Russia  🇷🇺 (alt)",
      som: 'Bank cards: all over the world — method №2',
      payMessageForNut: "Bank cards: all over the world — method №3",
      stripe: "Bank cards: all over the world — method №1 (Stripe)",
      bankCardsTemplateRussia: "Bank cards: only Russia  🇷🇺",
      payMessageForTether: "Important: transfer exactly the amount that will be indicated by the payment system. Otherwise, the funds will not be credited and the refund will cost $10.",
      alipayTitle: "To pay by AliPay scan QR-code:",
      alipayText: "...and write us your registration email address and send the screenshot of payment to live chat (↘).<br/>Your balance can't be toped up automatically. Please write us.<br/>The minimal payment is 10 RMB.",
      paymentMessageForConvertor: "Amount to be paid, {currency}:",
      paymentMessageForConvertorWithCommission: "Amount to be paid including commission, {currency}:",
      willBeCredited: "Will be credited, {currency}:",
      messageForConverterDollars: "We accept dollars and convert them into rubles at the rate: <span> {rate} </span> ₽ per dollar.",
      getNumbersFor: 'Getting number for {name}',
      usdHelpMessage: 'The information is provided for reference. Please consider the amount indicated in the interface of the payment instrument as a priority. Due to exchange rate fluctuations, the amounts may vary slightly.'
    },
    aliPay: {
      title: "AliPay and QQpayments",
      messageStep1: "1. Go by the link below, choose an amount and pay for it:",
      messageStep2: "2. enter paid code to add money on your Grizzly SMS balance:",
      send: "Send",
      couponNotFound: "Coupon not found",
      placeholderPassword: "Enter the code to add",
      waitingForRedirectPayment: "Wait for the transition to the site to make a payment",
      paymentCodeNotCorrect: "Payment code is not correct",
      buttonGetLink: "Pay via AliPay or QQ Get a code",
    },
    cryptoPayments: {
      label: "Crypto payments"
    }
  },
  servicesList: {
    "Выбор_страны": "Country selection",
    "Выбор_страны_для": "Country selection for {name}",
    title: "Select country",
    titlePopular: "Popular countries",
    chooseAnother: "Choose another",
    countryInputPlaceholder: "Select country",
    selectedLable: " Select",
    searchInputPlaceholder: "Search of Service",
    buttonWaiting: "Waiting...",
    buttonBuy: "Get",
    buttonRent: "Rent",
    message: 'There are currently no numbers available for the selected country',
    buttonUpdate: 'Update',
    quantity: 'qty',
    quantityFew: 'few',
    numberTemporarilyUnavailable: "Available only by API",
    orderFrom100numbers: "Order from 100 numbers via the support service",
    labelCountryHighPercentDelivery: "Countries with a high % delivery",
    labelSelectOtherCountry: "Choose another country",
    showMore: "ShowMore",
    from: 'from',
    retail: 'retail',
    authRetail: 'your price',
    wholesale: 'wholesale',
    getItFor: "Get it for",
    telegramMessagePrice: 'Wholesale prices info:',
    telegramMessageBot: 'Telegram Mini App:',
    telegramMessageNews: 'Service news:',
    scrollBtn: 'All services and countries',
    selectCountry: "Select country",
    selectService: "Select service",
    relatedArticlesFor: "Articles on the topic of «{name}»",
    requestNewServiceForm: {
      title: 'Offer a service',
      subTitle: "Didn't find the right service? Tell us more about it below and we will fix the situation. <br /> And right now we offer to use the section",
      anyother: 'Other',
      dataAboutSite: 'Website address *',
      smsMessage: 'SMS text *',
      countryService: 'Desired country (not necessary)',
      price: 'Number price (not necessary)',
      btnSend: 'Send',
      backToList: 'To the list',
      offerServiceBtn: "Offer a service",
    },
    linkToAccount: {
      btnMsg: "{accountName} accounts",
      text: "* Working with ready-made {accountName} accounts requires PC skills; the set of available countries differs from the set of countries for self-registration of accounts"
    }
  },
  languagesList: {
    "Выбор_сервиса": "Service selection",
    "Поиск_по_сервисам": "Search by services",
    "Выберите_услугу": "Choose a service",
    "Поиск_по_странам": "Search by country",
    "Успешная_покупка_номера": "The number has been successfully purchased. Soon it will appear in your \"Received numbers\"",
    serviceNotFound: 'Service not found',
    repeatMsg: 'wait < {min} min',
    repeatModal: {
      attempt: 'Attempt',
      from: 'out of',
      "Долгое_ожидание": 'A long wait?',
      "Предложение_ускорить_поиск": 'Let\'s try to find a more expensive number. The funds will be debited finally only after receiving an SMS. You can always cancel the number or not enter it in the service application. The higher the surcharge, the higher the probability of finding a number faster.',
      lowBalanceMessage: 'Insufficient funds',
    },
    errorMessages: {
      "NO_NUMBERS": "Number is unavailable. Try again",
      abort: "Number search stopped",
    }
  },
  blog: {
    title: 'Blog',
    buttonDetails: "More details",
    pageNumber: "Page №",
    tags: 'Tags',
    recentPost: 'Recent post',
    readMore: 'Similar articles',
    getNumberFor: "Get number for"
  },
  footer: {
    title: "The best service for receiving SMS messages online",
    userAgreement: "User agreement",
    referralProgram: "Referral program",
    returnsPolicy: "Returns policy",
    personalInformation: "Personal information",
    messageDisabledRussianNumbers: "Phone numbers from Russia are not available for sale",
    footerDisabledRussianNumbers: "Subscriber numbers of Russian mobile operators are not provided for temporary use due to the requirements of the Federal Law \"On Communications\" dated 07.07.2003 N 126-FZ and also on the basis of subparagraph g of paragraph 5 of the Rules for the centralized management of the public communications network, approved by Decree of the Government of the Russian Federation dated February 12, 2020 N 127 \"On approval of the Rules for the centralized management of the public communications network\""
  },
  registration: {
    title: 'Registration',
    registrationSuccessMessage: "User registered successfully! <br/> A link to confirm registration has been sent to the mail",
    nameInputPlaceholder: 'Name *',
    emailInputPlaceholder: 'E-mail *',
    passwordInputPlaceholder: 'Password *',
    passwordConfirmInputPlaceholder: 'Confirm Password *',
    buttonRegistration: 'Registration',
    buttonRegistrating: 'Waiting...',
    passwordsMismatchMessage: "Password mismatch",
    backHome: 'Back homepage',
    loginWithSocialNetworks: "Login with social networks:",
    orRegistrartion: "or register",
    iAgree: "I agree",
    privacyPolicy: "with personal data and cookie policy",
    userAgreement: "with public offer",
    alreadyHaveAccount: "Do you already have an account?",
    login: "Log In",
    messageSuccessConfirmRegistration: "User registered successfully",
  },
  authorization: {
    title: "Authorization",
    emailInputPlaceholder: 'E-mail',
    passwordInputPlaceholder: 'Password',
    tokenInputPlaceholder: 'The code',
    buttonRegistration: 'Authorization',
    buttonRegistrating: 'Waiting...',
    or: 'or',
    newUser: 'Are you a new user?',
    registration: 'Registration',
    forgotPassword: 'Do  forgot password?'
  },
  resetThePassword: {
    title: "Reset the password",
    buttonReset: 'Reset the password',
    buttonWaiting: 'Waiting...',
    emailInputPlaceholder: 'E-mail',
    message: "A link to reset your password has been sent to your email. Please check your inbox and follow the instructions."
  },
  errorsValidationForms: {
    requiredForFill: "Required for fill",
    emailErrorMessage: "Invalid email",
    requaredLengthMessage: "Minimum number of characters:"
  },
  advantagesBlock: {
    title: "Our advantages"
  },
  aboutBlock: {
    showMoreBtn: "More details",
    rollUp: "Roll Up",
  },
  serviceCard: {
    availableNumbers: "Available Numbers",
    price: "Price",
    buyNow: "Buy",
    emptyCountryListMessage: "There are no available numbers for the selected service.<br /> Please try again later"
  },
  feedbackForm: {
    title: "Feedback",
    namePlaceholder: "Name *",
    emailPlaceholder: "Email *",
    messagePlaceholder: "Message *",
    iAgree: "I agree",
    privacyPolicy: "with personal data and cookie policy",
    userAgreement: "public offer",
    buttonSend: 'Send',
    buttonRegistrating: 'Waiting...',
    sendError: "Send Error",
    applicationIsAccepted: "Your application is accepted"
  },
  copyToBuffer: {
    success: "Text copied successfully",
    error: "Copy error",
  },
  breadcrumbs: {
    homePage: "Homepage",
    blog: "Blog",
  },
  confirm: {
    passwordReset: "Password reset",
    newPassword: "New password",
    confirmPassword: "Confirm password",
    confirm: "Confirm",
    authorization: "Authorization",
    notMatch: "Password does not match",
    changedSuccessfully: "Password changed successfully",
  },
  errorPage: {
    pageNotFound: 'Page Not Found',
    somethingWentWrong: 'Something went wrong',
    backHomepage: 'Back homepage'
  },
  support: {
    inputSearch: {
      placeholder: "Enter a question",
    }
  },
  successPayment: {
    successMsg: "Payment was successful",
    waitMsg: "Payment is in progress",
    backHomepage: 'Back homepage'
  },
  searchPage: {
    placeholder: 'Search...',
    showMore: "Show more",
    nothingFoundMsg: "Nothing found for your request",
    'Сервисы': 'Services',
    'Статьи': "Articles",
    'Страницы': "Pages",
    'Все результаты': " All results",
  },
  common: {
    "downloadFile": "Download file"
  },
  lastArticlesBlock: {
    title: "Blog",
    textBtn: "Our blog",
  },
  reviewsBlock: {
    title: 'What Customers Say About Us',
    addReviewBtn: 'Add a review',
    yourName: 'Your name',
    messageTextarea: 'Please add your review',
    sendReview: 'Send review',
    success: 'The review has been sent successfully',
    validNameMsg: 'Enter your name',
    validMsg: 'Enter your review',
  },
  currencyToggle: {
    tooltipCurrency: 'Prices in USD are shown for reference. Operations with balance are carried out only in RUB'
  },
  pagination: {
    showing: 'showing',
    of: 'of',
  },
  additionalCashbackAccrual: {
    message: "<span>+10%</span> cashback to your balance in case the <span>1st</span> top-up from <span>{{balance}}</span> !",
    buttonCancel: "No, thanks",
    buttonPayment: "Top up balance"
  },
  countries: {
    title: 'List of available countries',
    countries: 'Countries',
    popularServices: 'Popular services',
    anotherServices: 'Choose service'
  },
  botLinkPage: {
    question: 'Do you really want to connect your account on the grizzlysms.com website using the telegram account <span> {userName}</span> for the bot?',
    ok: 'Yes',
    cancel: 'Cancel',
    success: "Telegram account successfully connected",
    alreadyHaveBot: 'You already have a telegram bot linked',
  },
  errors: {
    err1: 'An error has occurred. Please try again later.'
  },
  partnerPage: {
    becomePartner: "Become a partner",
    popUp: {
      title: "Become a partner",
      yourContactPlaceholder: "Your contact (Telegram, WeChat, WhatsApp, E-mail, other)",
      countryPlaceholder: "Countries of \"origin\" of SIM cards",
      equipmentPlaceholder: "Your Equipment",
      equipmentPortsPlaceholder: "Number of hardware ports",
      aboutPlaceholder: "What else do we need to know about you?",
      send: "Send",
      accept1: "I agree",
      accept2: "with the privacy policy and the use of the requested data",
      errorForm: "An error occurred, please try again later",
      successForm: "Application successfully sent",
    }
  },
  partnerFormBlock: {
    title: "Become a partner",
    contactTitle: "Telegram",
    contactPlaceholder: "Your preferred contact",
    countrySimCard: "Countries of phone numbers",
    countrySimCardPlaceholder: "Comma separated",
    yourEquipment: "GSM modems' models",
    yourEquipmentPlaceholder: "Tell me in detail",
    countPort: "Amount of COM-ports (16 or more)",
    countPortPlaceholder: "Specify in numbers",
    message: "What else do we need to know about you?",
    messagePlaceholder: "Describe your business in detail",
    accountGrizzlySmsEmail: "Grizzly SMS account (email address you used)"
  },
  partnerHeadingBlock: {
    becomePartner: "Become a partner",
    yieldCalculator: "Yield Calculator"
  },
  partnerCalculatorBlock: {
    country: "Country",
    priceSim: "SIM card cost",
    countPort: "Number of ports in the modem",
    netProfit: "Profit",
    netProfit3: "Profit for 3 months",
    netProfit6: "Income for 6 months",
    netProfit12: "Income for 12 months",
  },
  generalSearch: {
    searchByCountry: "Search by country",
    searchByService: "Search by service",
    title: "Prices for virtual numbers for receiving SMS",
    subtitle: "Select the service or country you need in the table below",
    tableHeaderService: "Service",
    tableHeaderCountry: "Country",
    tableHeaderCurrency: "Wholesale price",
    tableHeaderAmount: "Quantity",
  },
  profileLoyaltyProgram: {
    cashback: {
      title: "Cashback",
      week_deposit: "Top-up amount for the current week",
      projectedCashback: "Predicted cashback",
      tillNextRank: "Until the next rank left",
      cashbackPercentNextRank: "Cashback in the next rank",
      rang: "rank"
    },
    history: {
      title: "Participation history",
      message1: "For replenishment",
      message2: "cashback credited",
    },
    programConditions: {
      title: "Loyalty program terms",
      rangLeft: "Rank: obtaining condition",
      rangRight: "% cashback",
      rangNotLeft: "No rank: top-ups > 0 <1,000 ₽",
      rangNotRight: "no cashback",
      message: "Cashback is credited to users on <span>1st day of the week (Monday)</span> based on the results of the total amount of deposits over the past week. The percentage of cashback depends on the rank achieved by users by the end of last week.",
      replenishment: "replenishment",
      cashback: "Cashback"
    }
  },
  license: {
    licenseSuccess: 'The license has been purchased and sent to your email',
    buyKey: 'Buy a key on GrizzlySMS'
  },
  featuresTextBlock: {
    getIndividualNumber: "Get a private number"
  },
  sortComponent: {
    country: 'Top countries'
  },
  rentNumbers: {
    typeViews: {
      "number_activation": "Activation",
      "rent_number": "Rent",
      "readyAccounts": "Accounts"
    },
    rentInformation: {
      "rental_period": "Rental period",
      "rental_duration": "Rental duration",
      "rent": "Rent",
      "the_clock": "by hour",
      "for_days": "by day",
      "specify_rental_duration": "Specify rental duration",
      "maximum_number_hours": "Maximum number of hours",
      "minimal_number_hours": "Minimum number of hours",
      "number_successfully_rented": "Number successfully rented",
      "selected_time_not_available": "Number unavailable",
      "available_minimum_rental_period": "Available minimum rental period",
      "available_maximum_rental_period": "Available maximum rental period",
    },
    myRentNumbers: {
      "rent_virtual_number": "Rent a virtual number",
      "active": "Active",
      "received": "Received",
      "returned": "Canceled",
      "not_found": "Not found"
    },
    rentNumberCard: {
      "canceled": "Canceled",
      "expires_in": "Expires in",
      "extension": "Extend",
      "messageTitle": "Messages",
      "messageMessage": "No messages or calls have arrived yet. Send an SMS to the number or call, and the information will immediately be shown here.",
      "successCancel": "Number rental successfully canceled",
      "successNumberRenewal": "Number rental successfully extended",
      "rentalPeriodExpired": "Expired"
    },
    readyNumbers: {
      message: 'IMPORTANT! If you are initially purchasing ready-made accounts, you will need to review the information in the <a href="{link}"> "Accounts"</a> section on the "Support (FAQ)" page.',
      ok: 'I agree',
      toFaq: 'FAQ',
      cancel: 'Cancel'
    },
    popUpExtendLease: {
      "extend_number_rental": "Extend number rental",
      "going_renew_lease_number": "Are you going to renew your lease on a number?",
      "renewal_history": "Renewal history",
      "date": "Date",
      "price": "Price",
      "term_hours": "Time limit in hours",
      "number_rental_renewal_period": "Number rental renewal period",
      "lease_successfully_extended_until": "The lease was successfully extended until",
      "close": "Close",
      "short_day": "days",
      "short_hours": "hour.",
      "short_minutes": "min."
    },
    helpPopUp: {
      message: `<h4>Number activation</h4>
         <p>If you do not receive an SMS with a code within the first 20 minutes, you can cancel the number, the money will be returned to your account in full.</p>

         <h4>Rent a number</h4>
         <p>During the selected period, you will always have the opportunity to receive an unlimited number of SMS from the selected service.</p>
         <p>You can always restore access to your registered account!</p>
         <p>If you do not receive an SMS with a code within the first 20 minutes, you can cancel the number, the money will be returned to your account in full.</p>
         <p>If you have not received an SMS and more than 20 minutes have passed, it will be impossible to cancel the rental.</p>
         <p>A number sold for rent will not be resold for the corresponding service upon expiration of the lease period.</p>
         <p>The minimum rental period is 4 hours, the maximum is 4 weeks.</p>
         <p>There are two options for renting a number:</p>
         <p>Full rental - in this case, you can receive all SMS, the services of which are presented in our catalog.</p>
         <p>Rent a number for a specific service. In this case, you will receive SMS only from the service you have chosen.</p>

         <h4>Accounts</h4>
         <p>Don't want to waste your time waiting for SMS? You can buy a ready-made account made by our partners. Accounts for Telegram, VK and other services are usually available.</p>
         <p>Telegram accounts are provided in 2 formats: TDATA (an account in this format can be logged into on a PC and later transferred to a mobile device) and json+session (in this case you will need special software).</p>
         <p>The details of working with ready-made accounts, as well as the return conditions, are described in the FAQ section (the “Support” button in the site menu). Be sure to read this information before purchasing!</p>
      `
    }
  },
  comments: {
    "add_comment": "Add a comment",
    "comments": "Comments",
    "collapse": "Collapse",
    "expand": "Expand",
    "send_comment_moderation": "Your comment has been sent for moderation.",
    "ony_auth_user": "Available for registered members only"
  },
  saleAgreement: {
    "title": "Sale of licenses",
    "name": "Name",
    "type_po": "Software type",
    "price": "Price",
    "date_purchase": "Date of purchase",
  },
  freeNumbersPopUpRegistration: {
    subtitle: "The creation of an account with a free number was not working? Sign up and select a private number for the service — <span>from $0.01!</span>",
    button: "TRY"
  },
  accountShop: {
    mainSectionInfo: {
      my_paid_accounts_title: "My paid accounts",
      my_paid_accounts_message: "Here you see a list of all accounts ever purchased and the required login information",
      my_paid_accounts_button: "Purchasing accounts",

      purchasing_ready_accounts_title: "Purchasing accounts",
      purchasing_ready_accounts_message: "We understand that it is not always possible to receive SMS within 20 minutes. In this section you can buy a ready-made account for any popular service.",
      purchasing_ready_accounts_button: "My paid accounts",
    },
    selectedService: {
      title: "Select service",
      search_placeholder: "Search by service name"
    },
    selectedOffer: {
      title: "Select offer"
    },
    dialogOffer: {
      title: "Buy account",
      name_of_product: "Name of product",
      description_of_product: "Product description",
      characteristics: "Characteristics",
      registration_method: "Registration method",
      country: "Country",
      account_format: "Account format",
      availability_cloud_password: "Cloud password",
      information_about_purchased_product: "Information about the purchased product",
      download_files: "Download files",
      buy_more: "Buy more",
      yes: "Yes",
      no: "No",
      minimum_order_quantity: "Minimum order quantity"
    },
    myServices: {
      messageAuth: "You need to log in to your account",
      messageEmpty: "No purchased accounts"
    }
  },
  partner: {
    breadcrumbs_title: "Partner",
    documentation: "Documentation",
    activations_history: {
      activations_history: "Activations history",
      filter: "Filter",
      export_in: "Export to",
      number: "Number",
      load_more: 'Show more',
      total_rows: 'Total: {{ count }} rows',
      select_status: 'Select status',
      date_activation: 'Activation date',
      date_from: 'Date from',
      date_to: 'Date to',
      ready: 'Ready',
      canceled: 'Canceled',
      activation: "Activation"
    },
    sim_card: {
      sim_card: "Sim Card",
      port: 'Port',
      info: 'Info',
      earned: 'Earned',
      countServices: 'Count services'
    },
    price_regulator: {
      price_regulator: "Price regulator",
      select_country_started: "Select a country to get started",
      services: "Services",
      search_service: "Search by service",
      all_services: "All services",
      all_countries: "All countries",
      popular_services: "Popular",
      market_price: "Set market price",
      competitors_prices: "Show competitor prices",
      pice_successfully_changed: "Price successfully changed",
    },
    withdrawal_founds: {
      withdrawal_founds: 'Withdrawal founds',
      balance: 'Your balance',
      order_payments: 'Order a payment',
      currency_type: 'Currency type',
      withdrawal_method: 'Withdrawal method',
      order_status: 'Order status',
      select_method: 'Select a method',
      select_status: 'Select status',
      requisites: 'Requisites',
      sum: 'Sum',
      currency: 'Currency',
      date: 'Date',
      status: 'Status',
      nothing_found: 'Nothing found',
      amount_in_currency: 'Amount in currency',
      wallet_card_number: 'Wallet/card number',
      comment: 'Comment',
      not_necessary_placeholder: 'Not necessary',
      all_btn: 'All',
      error_required: 'Fill in all required fields',
      success_msg: 'Your application is accepted',
      select_currency_type: "Select currency type",
      warning_message: '<span>IMPORTANT!</span> Make sure you entered the correct payment details when creating your application above️. The administration of grizzlysms.com is not responsible in case of loss of funds due to incorrect payment details.'
    },
    statistic: {
      statistic: 'Monetization statistic',
      average_SIM: 'Average <br/> per SIM',
      top_services: 'Top services <br/>for 24 hours',
      activations_count: 'Activations count <br />for 24 hours',
      last_24_hours: 'For last <br/>24 hours'
    },
    dialog_competitors_prices: {
      your_price: "Your price",
      competitors_prices: "Competitors' prices"
    },
  },
  newPayments: {
    minAmount: 'Minimum payment amount:',
    commission: 'Commission:',
  },
  soft: {
    "submit_application": "Submit application",
    "search_by_name": "Search by name",
    "all_services": "All<br/>services",
    "activations": "Activations"
  },
  productLicensesBlock: {
    availableAt: "Available at",
  },
  documentationApi: {
    rent: "API rent",
    tables: "Tables",
    activations: "API activations",
    expandContent: "Expand content",
    collapseContent: "Collapse content",
    successLinkCopy: "Link copied successfully",
    successApiKeyCopy: "API Key copied successfully",

    select: "Select",
    fillIn: "Fill in",
    result: "Result",
    execute: "Execute",
    urlRequest: "Request URL",
    resultLoading: "Loading result...",
    testMethodNow: "Test this method now"
  },
  marketplaceRegistrationPage: {
    pageTitle: "Store registration",
    formInputs: {
      name: {
        label: 'Come up with a name for your store. Perhaps what you call it will reflect the essence of your product and help the buyer when choosing.',
        placeholder: 'Name',
        errorMessage: 'Required to fill out'
      },
      shopDescription: {
        label: 'Store Description',
        placeholder: 'Come up with a description of the store, highlight its uniqueness and features...',
        errorMessage: 'Required to fill out'
      },
      email: {
        label: 'Your Email',
        placeholder: 'Please enter your email address for feedback...',
        errorMessageForNull: 'Required to fill out',
        errorMessageNoValid: 'Invalid email address'
      },
    },
    formSendButton: 'Register a store',
    rulesMarket: {
      iAgree: 'I agree',
      rulesPhrase: 'Grizzlysms.com marketplace rules and regulations'
    },
    offerAgreement: {
      iAccept: 'I accept',
      offerPhrase: 'offer agreement'
    },
    toastSuccessMessage: 'Your application has been sent'
  },
  bannerForBuyAccounts: {
    title: 'Is it difficult to activate by SMS?',
    subtitle: 'Buy a ready-made account, it\'s much easier!',
    button: 'Buy a ready-made account'
  }
}
