//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ProfilePageMixin from "~/node_modules/sitis-nuxt/lib/mixins/ProfilePageMixin";

export default {
  name: "NavigationPanel",

  props: {
    isLogin: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      balance: null,
      updateBalance: null,
    };
  },
  mixins: [ProfilePageMixin],

  async mounted() {
    if (this.isLogin) {
      await this.getBalance();
    }

    this.$root.$on('getBalance', async () => {
      await this.getBalance();
    })
  },

  destroyed() {
    clearInterval(this.updateBalance);
  },

  computed: {
    isHiddenUserButtons: function () {
      const authToken = this.$cookies.get("authToken");
      if (!authToken) {
        return false
      }

      const isLoading = this.$store.state.user.isLoadingUser;
      if (!isLoading) {
        return false
      }

      return true
    },

    getCurrency() {
      return this.$store.getters["currency/currentCurrency"];
    },
    saleAgreement: function () {
      return Boolean(this.$store.state?.user?.user?.dynamicFields?.settings?.is_agent)
    },

    userBalanceLabel: function () {
      const _balance = this.$store.state["user-local"]?.balance || 0;
      const _currencyCode = this.$store.getters["currency/currentCurrency"];
      const _currencySymbol = Boolean(_currencyCode == 'rub') ? '₽' : '$';

      return [
        this.$t('profileNavMenu.balance'),
        ':',
        `<span>${[this.$convertCurrency(_balance), _currencySymbol].join(' ')}</span>`
      ].join('')
    },

    isPartner: function () {
      return this.$store.state.user?.user?.dynamicFields?.is_p
    }
  },

  methods: {
    async onLogoutProfile() {
      this.userLogout();
      this.$router.replace(this.localePath("/"));
    },

    async getBalance() {
      if (!this.isLogin) {
        clearInterval(this.updateBalance);
        return
      }

      let queryCount = 0;
      const balance = await this.$api.agent.transport.get("sms-users/balance")
        .catch(err => {});
      this.balance = balance?.data || 0;
      this.$store.commit("user-local/setBalance", balance?.data || 0);

      if (this.$route.path != "/profile" || this.$route.path != "/telegram-expert" || !this.isLogin) {
        clearInterval(this.updateBalance);
        this.updateBalance = null;
        return null;
      }

      if (!!this.updateBalance) {
        return null;
      }

      this.updateBalance = setInterval(async () => {
        ++queryCount;

        if (queryCount == 90 || !this.isLogin) {
          clearInterval(this.updateBalance);
        }

        await this.getBalance();
      }, 10000);
    },
  },
  watch: {
    async $route() {
      if (this.isLogin) {
        await this.getBalance();
      }
    },

    async isLogin() {
      if (this.isLogin) {
        await this.getBalance();
      }
    }
  },
  components: {
    // NavigationPanelMobile,
  },
};
