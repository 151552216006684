//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


export default {
  name: "currencyToggle",
  emits: ['onClick'],

  data() {
    return {
      isOpenList: false
    }
  },

  computed: {
    getCurrency() {
      return this.$store.getters["currency/currentCurrency"];
    },

    localItemStore: function () {
      return String(this.$store.state?.["ab-test"]?.['ab-test__hide-ruble-currency'] || "")
    },
    isHideRubleCurrency: function () {
      if (!!String(this.localItemStore)) {
        return Boolean(String(this.localItemStore) === 'true')
      }

      const cookies = this.$cookies.get("ab-test__hide-ruble-currency");
      return Boolean(String(cookies) === 'true');
    }
  },

  watch: {
    localItemStore: function () {
      console.log('localItemStore: ', this.localItemStore)
    },
    "$cookies.ab-test__hide-ruble-currency": function () {
      console.log('asdasdasdasdasd')
    }
  },

  methods: {
    changeOpen: function () {
      if (this.isHideRubleCurrency) {
        return
      }
      this.isOpenList = !this.isOpenList;
    },

    onClose() {
      this.isOpenList = false;
    },

    onToggle(currency) {
      this.onClose();
      this.$cookies.set('currency', currency);
      this.$store.commit('currency/setCurrency', currency);
      this.$emit('onClick')
    },
    _onToggle() {
      this.onClose();
      this.$cookies.set('currency', this.getCurrency === 'rub' ? 'usd' : 'rub');
      const currency = this.getCurrency === 'rub' ? 'usd' : 'rub'
      this.$store.commit('currency/setCurrency', currency);
      this.$emit('onClick')
    }
  },
}
