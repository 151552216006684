import dictionaryRuEn from "~/constant/dictionary-ru-en.json";
import dictionaryEnRu from "~/constant/dictionary-en-ru.json";

export const state = () => ({
  services: [],
  servicesPage: 1,
  servicesTotalPage: 2,
  servicesTotalCount: 0,
  servicesSearch: "",
  servicesLoading: true,
  servicesSelected: null,


  // services: [],
  servicesPopular: [],
  languages: [],

  activeServiceId: null,
  activeServiceIdExternal: null,
  activeLanguageId: null,

  typeNumberReceiving: "buy", // buy | rent

  isWholesale: false,
  isLoadServices: true,
  isLoadLanguages: true,
  isLoadServicesAll: true,
  isLoadTypeNumberReceiving: false
});

export const mutations = {
  setListServices(state, services) {
    state.services = services;
  },
  setListServicesPopular(state, services) {
    state.servicesPopular = services;
    state.isLoadTypeNumberReceiving = false;
  },
  setListLanguages(state, languages) {
    state.languages = languages;
  },

  setActiveServiceId(state, activeServiceId) {
    state.activeServiceId = activeServiceId;
  },
  setActiveServiceIdExternal(state, activeServiceIdExternal) {
    state.activeServiceIdExternal = activeServiceIdExternal;
  },
  setTypeNumberReceiving(state, val) {
    state.typeNumberReceiving = val;
  },

  setLoadLanguages(state, isLoadLanguages) {
    state.isLoadLanguages = isLoadLanguages;
  },
  setIsWholesale(state, isWholesale) {
    state.isWholesale = isWholesale;
    this.$cookies.set("isWholesale", isWholesale);
  },
  setLoadTypeNumberReceiving(state, isLoadTypeNumberReceiving) {
    state.isLoadTypeNumberReceiving = isLoadTypeNumberReceiving;
  },

  setServicesMore: function (state, { page, items, loading, totalPages }) {
    state.services = items;
    state.servicesPage = page;
    state.servicesLoading = loading;
    state.servicesTotalPage = totalPages;
  },
  setServicesSearch: function (state, { search }) {
    state.services = [];
    state.servicesPage = 1;
    state.servicesSearch = search;
  },
  setServicesLoading: function (state, loading) {
    state.servicesLoading = loading;
  },
  setServicesTotalCount: function (state, count) {
    state.servicesTotalCount = count;
  },
  setServicesSelected: function (state, item) {
    state.servicesSelected = item;
  },
};

export const actions = {
  getLanguagesList: async function ({commit, state, rootState}) {
    commit("setLoadLanguages", true);

    let userId = rootState?.user?.user?.id ? rootState?.user?.user?.id : 'guest';
    if (!rootState?.user?.user?.dynamicFields?.custom_price) {
      userId = 'guest';
    }

    const isWholesale = Number(state.isWholesale);

    let actionUrl = `/country/get-prices/${state.activeServiceId}?wholesale=${isWholesale}&user=${userId}`;
    if (state.typeNumberReceiving === 'rent') {
      actionUrl = `/rent/get-prices?service_external_id=${state.activeServiceIdExternal}&wholesale=${isWholesale}&user=${userId}`
    }

    const response = await this.$api.agent.transport
      .get(actionUrl)
      .then((res) => {
        return res.data
      }).catch(() => {
        return []
      })

    commit("setListLanguages", response);
    commit("setLoadLanguages", false);
    commit('setLoadTypeNumberReceiving', false);
  },

  initActiveServiceId: async function (params) {
    const {state, commit, rootState, dispatch} = params;

    let activeServiceId = this.$cookies.get("selectedServiceId");
    const isFindServices = Boolean((state?.services || []).find((t) => Boolean(String(t?.id) === String(activeServiceId))));
    const isFindServicesPopular = Boolean((state?.servicesPopular || []).find((t) => Boolean(String(t?.id) === String(activeServiceId))));
    if (!isFindServices && !isFindServicesPopular) {
      activeServiceId = null;
    }

    if (activeServiceId === undefined) {
      activeServiceId = null;
    }
    if (activeServiceId === null) {
      activeServiceId = Number.parseFloat(rootState.appState?.vars?.["Сервис выбора по умолчанию"] || "52");
    }

    await commit("setActiveServiceId", activeServiceId);
    await dispatch("getLanguagesList");
  },
  initIsWholesale: async function ({commit}) {
    let isWholesale = this.$cookies.get("isWholesale");
    if (isWholesale === undefined) {
      isWholesale = false
    }
    commit("setIsWholesale", isWholesale);
  },

  getServicesMore: async function (params) {
    await params.commit("setServicesLoading", true);

    const currentPage = params.state?.servicesPage || 1;
    const currentSearch = params.state?.servicesSearch || '';
    const searchString = [
      `per-page=40`,
      `page=${ currentPage }`,
      Boolean(currentSearch.length >=1) && `search=${ currentSearch }`
    ].filter((t) => !!t).join("&")

    let actionUrl = `/service?${ searchString }`;
    if (params.state?.typeNumberReceiving === "rent") {
      actionUrl = `rent?${ searchString }`;
    }

    const response = await this.$api.agent.transport.get(actionUrl)
      .then((res) => {
        return {
          data: [...(res?.data || [])].map((t) => {
            return {
              ...t,
              icon: t?.icon_id || t?.icon
            }
          }),
          totalPages: Number.parseFloat(res?.headers?.['x-pagination-page-count'] || '0')
        }
      })
      .catch(() => {
        return {
          data: [],
          totalPages: 0
        }
      });

    const newPage = currentPage + 1;
    params.commit("setServicesMore", {
      page: newPage,
      loading: false,
      totalPages: response.totalPages || 0,
      items: [...(params.state.services || []), ...(response?.data || [])],
    });
  },
  getServicesSearch: async function (params, { search }) {
    await params.commit("setServicesSearch", { search });
    await params.dispatch("getServicesMore");
  },

  getPopularServicesList: async function ({ commit, dispatch, state }) {
    let apiAction = Boolean(state.typeNumberReceiving === "rent") ? '/rent' : '/service';
    apiAction = [apiAction, 'page=1&per-page=12'].join('?');

    const response = await this.$api.agent.transport.get(apiAction)
      .then((res) => res)
      .catch(() => null);

    const items = [...(response?.data || [])]
      .map((t) => {
        return {
          ...t,
          icon: t.icon_id || t.icon,
        }
      });

    const totalCount = Number.parseFloat(response?.headers?.['x-pagination-total-count'] || '0');

    commit("setListServicesPopular", items);
    commit("setServicesTotalCount", totalCount);
  },
  changeTypeNumberReceiving: async function ({commit, dispatch, rootState, state}, val) {
    if (val === 'readyAccounts') {
      commit('setTypeNumberReceiving', val);
      return;
    }
    if (val === state.typeNumberReceiving) {
      return
    }

    commit('setTypeNumberReceiving', val);
    commit('setLoadTypeNumberReceiving', true);
    commit("setServicesSearch", { search: "" });

    await dispatch("getPopularServicesList");

    const buyService = Number.parseFloat(rootState.appState?.vars?.["Сервис выбора по умолчанию"] || "52");
    const rentService = String(rootState.appState?.vars?.["Сервис выбора по умолчанию (аренда)"] || "tg");
    await commit("setActiveServiceId", buyService);
    await commit("setActiveServiceIdExternal", rentService);

    await dispatch("getLanguagesList");
  },
};
