//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import YandexMetricaMixin from "~/mixin/YandexMetricaMixin";
import {sortServiceList} from "~/helpers/sortServiceList";
import SortComponent from "~/components/profile/sort-component/SortComponent.vue";
import ReadyNumbers from "~/components/profile/LanguagesList/ready-numbers/ReadyNumbers.vue";
import {scrollToElement} from "~/helpers/scrollToElement";
import CustomAutocompleteServices from "~/components/service/CustomAutocompleteServices.vue";

export default {
  name: "languagesList",

  data: function () {
    return {
      loadingCountryExternalId: [],
      languageLPagesList: [],
      isBuyingNumber: false,
      search: "",
      searchableService: "",

      pagesCounter: 1,

      isPopUpHelp: false,
      isFormOfferService: false,
      isShowAllCounties: Boolean('desktop' === this.$store.state["local-settings"].viewport),
      currentSort: 'name',

      //   settingsForRepeatBuyNumbers
      isShowPopupRepeat: false,
      isNeedAutorepeat: false,
      isAbortRequest: false,

      currentServiceWithCountry: null,
      currentServiceForRepeat: '',

      coefficientMaxPrice: 0,

      timerForRequest: 20 * 60, // 20 минут секундах
      requestCounter: 0,

      isLoadingFullListRender: true
    }
  },

  mixins: [
    YandexMetricaMixin
  ],

  computed: {
    sortedServicesList() {
      const servicesList = [...this.languagesList].map(t => {
        return {...t, price: Number.parseFloat(t.price)}
      });
      return sortServiceList(servicesList, this.currentSort);
    },
    placholdersEnptyFill() {
      return Array(15).fill(0)
    },

    optionsServicesList() {
      if (!this.searchableService) {
        return this.servicesList
      }
      const searchableService = String(this.searchableService || '').toLocaleLowerCase();
      return [...(this.servicesList || [])]
        .filter((service) => {
          const name = String(service?.name || '').toLocaleLowerCase();
          const nameEnRu = String(service?.nameTranscriptionEnRu || '').toLocaleLowerCase();
          const nameRuEn = String(service?.nameTranscriptionRuEn || '').toLocaleLowerCase();
          const slug = String(service?.slug || '').toLocaleLowerCase();
          if (
            name.includes(searchableService) ||
            slug.includes(searchableService) ||
            nameEnRu.includes(searchableService) ||
            nameRuEn.includes(searchableService)
          ) {
            return true
          }
          return false
        })
    },

    currentService: function () {
      let serviceId = this.$store.state["languages-list"].activeServiceId;
      if (this.typeNumberReceiving === "rent") {
        serviceId = this.$store.state["languages-list"].activeServiceIdExternal;
      }

      const servicesPopular = this.$store.state?.["languages-list"]?.servicesPopular || [];

      let servicesList = this.servicesList || [];
      if (servicesList.length <= 0) {
        servicesList = servicesPopular
      }

      let findService = servicesList.find((t) => Boolean((t?.id === serviceId) || (t?.external_id === serviceId)));
      if (!findService) {
        findService = servicesPopular.find((t) => Boolean((t?.id === serviceId) || (t?.external_id === serviceId)))
      }
      if (!findService) {
        const servicesSelected = this.$store.state?.["languages-list"]?.servicesSelected || null;
        if (Boolean((servicesSelected?.id === serviceId) || (servicesSelected?.external_id === serviceId))) {
          findService = servicesSelected;
        }
      }

      if (findService === null) {
        return null;
      }
      return findService;
    },
    isWholesale: function () {
      return this.$store.state?.["languages-list"]?.isWholesale || false
    },
    currentServiceId: function () {
      const authToken = this.$cookies.get("authToken");
      if (!process.client && authToken) {
        return ""
      }
      return String(this.currentService?.id || "")
    },
    currentServiceName: function () {
      const authToken = this.$cookies.get("authToken");
      if (!process.client && authToken) {
        return ""
      }
      return this.currentService?.name || ""
    },

    servicesList: function () {
      return this.$store.state["languages-list"]?.services || []
    },
    popularServicesList: function () {
      return this.$store.state?.["languages-list"]?.servicesPopular
    },

    currentTabValue() {
      return this.$store.state['languages-list'].typeNumberReceiving
    },

    languagesList: function () {
      let list = this.$store.state["languages-list"]?.languages || [];

      if (!this.search) {
        if (!this.isShowAllCounties) {
          return [...list].splice(0, 14)
        }

        return list
      }

      const search = this.search.toLocaleLowerCase();
      const filterList = list.filter((t) => {
        const countrySlug = (t?.country_slug || "").toLocaleLowerCase();
        const name = (t?.name || "").toLocaleLowerCase();

        return Boolean(
          countrySlug.indexOf(search) > -1 ||
          name.indexOf(search) > -1
        )
      });
      if (filterList.length <= 0) {
        this.showFormOfferService();
        return filterList
      }

      return filterList
    },

    languagesListFull: function () {
      return this.$store.getters['countries/countriesList']
    },

    isLoadLanguagesList: function () {
      return this.$store.state["languages-list"].isLoadLanguages || this.isLoadTypeNumberReceiving
    },

    user: function () {
      return this.$store.state?.user?.user || null
    },

    typeNumberReceiving: function () {
      return this.$store.state?.["languages-list"]?.typeNumberReceiving || "buy"
    },

    typesNumberReceiving: function () {
      return [
        {label: this.$t('rentNumbers.typeViews.number_activation'), value: "buy"},
        {label: this.$t('rentNumbers.typeViews.rent_number'), value: "rent", beta: true},
        {label: this.$t('rentNumbers.typeViews.readyAccounts'), value: "readyAccounts"},
      ]
    },

    isUserLoading() {
      return this.$store.state.appState.loading?.user;
    },

    isRentFunctional: function () {
      const isShowRent = Boolean(String(this.$cookies.get("show-rent") || "0") === "1");
      if (isShowRent) {
        return true
      }
      return Boolean(this.$nuxt.context?.env?.activeRentFunctional === '1')
    },

    isLoadTypeNumberReceiving: function () {
      return this.$store.state["languages-list"]?.isLoadTypeNumberReceiving
    },

    urlActionForAutoBuyNumber: function () {
      const apiMain = (process.env.baseApiUrl || '').replace('https://', 'https://api.');
      const apiKey = this.$store.state?.user?.user?.dynamicFields?.settings?.apiKey || "";
      const roundDown = function(value, decimals) {
        return Math.floor(value * Math.pow(10, decimals)) / Math.pow(10, decimals);
      }
      if (this.currentServiceWithCountry.price / 100 !== this.isNewMaxPrice) {
        return [apiMain, `/stubs/handler_api.php?action=getNumber&service=_service_&from=front&country=_country_&api_key=${ apiKey }&maxPrice=${String(this.isNewMaxPrice * 100)}`].join('')
      }
      return [apiMain, `/stubs/handler_api.php?action=getNumber&service=_service_&from=front&country=_country_&api_key=${ apiKey }`].join('');
    },
    isNewMaxPrice: function () {
      const oldPrice = this.currentServiceWithCountry.price / 100;
      const newPrice = oldPrice + oldPrice * Number(this.coefficientMaxPrice);
      return newPrice
    },
  },

  components: {
    ReadyNumbers,
    SortComponent,
    CustomAutocompleteServices,
    PopUpHelp: () => import("./LanguagesList/PopUpHelp.vue"),
    LanguageCard: () => import("./LanguagesList/LanguageCard.vue"),
    RequestNewServiceForm: () => import("~/components/profile/RequestNewServiceForm.vue"),
    PopupRepeatBuyNumber: () => import('~/components/profile/LanguagesList/PopupRepeatBuyNumber.vue')
  },

  watch: {
    isUserLoading: async function () {
      await this.initListServices();
    }
  },

  mounted: async function () {
    if (window.innerWidth > 992) {
      this.isShowAllCounties = true;
    }

    setTimeout(() => {
      this.isLoadingFullListRender = false
    }, 3000);
  },

  methods: {
    closeRepeatPopup() {
      this.isAbortRequest = true;
      this.isShowPopupRepeat = false;
    },
    changeCurrentSort(sort) {
      this.currentSort = sort;
    },
    showAllCountries() {
      this.isShowAllCounties = true;
    },

    // Логика работы со списком сервисов
    initListServices: async function () {
      if (this.isUserLoading) {
        return
      }

      const isPersonalPrice = true;
      if (!isPersonalPrice) {
        return
      }
    },

    changeActiveService: async function (service, event) {
      // Запись нового активного ID услуги в куки
      this.$cookies.set("selectedServiceId", service?.id, {
        path: "/",
        expires: new Date(new Date().setFullYear(new Date().getFullYear() + 5))
      });
      this.$cookies.set("selectedServiceIdExternal", service?.external_id, {
        path: "/",
        expires: new Date(new Date().setFullYear(new Date().getFullYear() + 5))
      });

      // Установка активного сервиса в локальное хранилище
      this.$store.commit("languages-list/setActiveServiceId", service.id);
      this.$store.commit("languages-list/setActiveServiceIdExternal", service?.external_id);

      if (window.innerWidth < 990) {
        scrollToElement('.services-list-section');
      }

      // Получение списка стран по сервису
      await this.$store.dispatch("languages-list/getLanguagesList");

      return null
    },
    changeTypeNumberReceiving: function (val) {
      this.$store.dispatch("languages-list/changeTypeNumberReceiving", val)
    },

    // Общая логика
    changeWholesale: async function (bool) {
      this.$store.commit("languages-list/setIsWholesale", bool);
      await this.$store.dispatch("languages-list/getLanguagesList");
    },

    clearRepeatObject() {
      this.isShowPopupRepeat = false;
      this.isNeedAutorepeat = false;
      this.currentServiceForRepeat = '';
      this.currentServiceWithCountry = null;
      this.coefficientMaxPrice = 0;
      this.requestCounter = 0;
    },

    onBuyNumber: async function (language) {
      const paymentTest = this.$cookies.get("payment-test");

      if (!this.user && paymentTest) {
        await this.$router.push(this.localePath(`/profile/pay?service=${language.service_external_id}&country=${language.country_external_id}`));
        return;
      }

      if (!this.user && !paymentTest) {
        await this.$router.push(this.localePath("/registration?goback=1"));
        return
      }

      this.updateLoadingCountryExternalId(language.country_external_id);
      // Проверка баланса пользователя
      const balance = await this.$api.agent.transport.get("sms-users/balance")
        .then((res) => {
          return res.data
        }).catch(() => {
          return 0
        });

      if (paymentTest && balance < +language.price) {
        this.updateLoadingCountryExternalId(language.country_external_id);
        await this.$router.push(this.localePath(`/profile/pay?service=${language.service_external_id}&country=${language.country_external_id}`));
        return;
      }

      if (!paymentTest && balance < +language.price) {
        this.updateLoadingCountryExternalId(language.country_external_id);
        await this.$router.push(this.localePath("/profile/pay"));
        return
      }

      // Яндекс метрика
      this.yandexGoalCheck('buy_left-sidebar_attempt');
      this.yandexSendUserInfo({
        userId: this?.user?.id,
        userEmail: this?.user?.email
      })

      // Покупка номера
      this.isNeedAutorepeat = language.isNeedRepeat;
      this.currentServiceWithCountry = language;

      const response = await this.onBuyNumberMethod({
        countryExternalId: language.country_external_id,
        serviceExternalId: language.service_external_id
      });

      if (response && response.status === 200) {
        this.clearRepeatObject();

        // Яндекс метрика
        this.yandexGoalCheck('buy_left-sidebar_success');
        this.yandexSendUserID({
          userId: this?.user?.id,
        })

        this.updateLoadingCountryExternalId(language.country_external_id);

        // Переход на страницу профиля
        if (this.$route.path !== "/profile") {
          await this.$router.push(this.localePath("/profile"));
        }

        this.$toast.success(this.$t("languagesList.Успешная_покупка_номера"))
        return;
      }

      if (this.isAbortRequest) {
        this.clearRepeatObject();
        this.updateLoadingCountryExternalId(language.country_external_id);
        this.$toast.error(response?.error?.data?.message || this.$t("languagesList.errorMessages.abort"));
        this.isAbortRequest = false;

        return;
      }

      this.updateLoadingCountryExternalId(language.country_external_id);
      this.$toast.error(response?.error?.data?.message || "Error");


      this.currentServiceForRepeat = `${language.name}-${language.service_slug}`;

    },

    updateLoadingCountryExternalId: function (id) {
      let loadingCountryExternalId = [...this.loadingCountryExternalId];

      const index = loadingCountryExternalId.findIndex((t) => t === id);
      if (index > -1) {
        loadingCountryExternalId.splice(index, 1)
      } else {
        loadingCountryExternalId.push(id)
      }

      this.loadingCountryExternalId = loadingCountryExternalId;
    },

    showFormOfferService: function () {
      this.isFormOfferService = true;
    },
    hideFormOfferService: function () {
      this.isFormOfferService = false;
    },

    openHelpMessage: function () {
      this.isPopUpHelp = true;
    },
    closeHelpMessage: function () {
      this.isPopUpHelp = false;
    },

    async onBuyNumberMethod(options = {}) {
      const {countryExternalId, serviceExternalId} = options;

      const startTime = Date.now();

      const attemptRequest = async () => {

        if (this.isNeedAutorepeat) {
          this.requestCounter++;
          this.isShowPopupRepeat = true;
        }

        let newActionUrl = `sms-users/get-number/${countryExternalId}/${serviceExternalId}`;
        if (this.isNeedAutorepeat) {
          newActionUrl = this.urlActionForAutoBuyNumber;
          newActionUrl = newActionUrl.replace('_country_', countryExternalId);
          newActionUrl = newActionUrl.replace('_service_', serviceExternalId);
        }

        const response = await this.$api.agent.transport.get(newActionUrl).then((res) => {
          if (this.isNeedAutorepeat && !String(res?.data || '').includes('ACCESS_NUMBER')) {
            return {error: res?.data}
          }

          // if (!String(res?.data || '').includes('ACCESS_NUMBER')) {
          //   return {error: res?.data}
          // }
          if (res?.data === "NO_NUMBERS") {
            return {error: 'NO_NUMBERS'}
          }
          if (res?.data === "NO_BALANCE") {
            return {error: 'NO_BALANCE'}
          }
          return res
        }).catch((err) => {
          if (this.isNeedAutorepeat) {
            return {error: err}
          }
          return {error: err.response}
        });
        if (response?.error) {
          if (response?.error === "NO_BALANCE") {
            return {error: "NO_BALANCE"};
          }
          if (!this.isNeedAutorepeat && response?.error === "NO_NUMBERS") {
            return {error: {data: { message: this.$t('languagesList.errorMessages.NO_NUMBERS') }}};
          }
          if (!this.isNeedAutorepeat && response?.error === "LIMIT!!!") {
            return {error: {data: { message: this.$t('languagesList.errorMessages.NO_NUMBERS') }}};
          }
          if (!this.isNeedAutorepeat && response.error) {
            return {error: response.error};
          }

          if (this.isAbortRequest) {
            this.clearRepeatObject();
            return {error: response.error};
          }
          const currentTime = Date.now();
          if (currentTime - startTime < (this.timerForRequest * 1000)) {
            return await attemptRequest();
          } else {
            this.clearRepeatObject();
            return {error: response.error};
          }
          return
        }
        return response
      };
      const _attemptRequest = async () => {
        try {
          if (this.isNeedAutorepeat) {
            this.requestCounter++;
            this.isShowPopupRepeat = true;
          }

          let newActionUrl = this.urlActionForAutoBuyNumber;
          newActionUrl = newActionUrl.replace('_country_', countryExternalId);
          newActionUrl = newActionUrl.replace('_service_', serviceExternalId);

          return await this.$api.agent.transport.get(newActionUrl).then((res) => {
            if (res?.data === "NO_NUMBERS") {
              return new Error('NO_NUMBERS');
            }
            return res
          });
          // return await this.$api.agent.transport.get(`sms-users/get-number/${countryExternalId}/${serviceExternalId}`);
        } catch (err) {
          const currentTime = Date.now();
          if (!this.isNeedAutorepeat) {
            return {error: err.response};
          }
          if (this.isAbortRequest) {
            this.clearRepeatObject();
            return {error: err.response};
          }
          if (currentTime - startTime < (this.timerForRequest * 1000)) {
            return await attemptRequest();
          } else {
            // Время ожидания истекло, возвращаем ошибку
            this.clearRepeatObject();
            return {error: err.response};
          }
        }
      };

      return await attemptRequest();
    },
    updateCoefficientMaxPrice: function(coefficient) {
      const prepareCoefficient = Number(coefficient) / 100;
      if (prepareCoefficient !== this.coefficientMaxPrice) {
        this.coefficientMaxPrice = Number(coefficient) / 100;
        return
      }
      if (prepareCoefficient === this.coefficientMaxPrice) {
        this.coefficientMaxPrice = 0;
        return
      }
    },
  }
};
